
import React, { createContext, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link, Navigate, Outlet } from "react-router-dom"

import "./style.css"


// Application-Level Elements
import Navbar from "./components/Navbar";
import Layout from "./components/Layout"; 

// Pages
import Home from "./pages/Home";




import NotFoundPage from "./pages/NotFound.jsx"






export default function App() {

     
        

      return (


          <BrowserRouter>
                  
                    <Routes>
                    
                        <Route element={<Layout />}>
                      
                          <Route index element={<Home />}/>
                          <Route path="*" element={<NotFoundPage />}/>

                        </Route>


                        
                    </Routes>
            

          </BrowserRouter>
                  


        
  
    )
}
