import React from "react"



export default function ComingSoon() {
    
    return (
 
        
 
        <section className="homepage--comingsoon">
        
            <div>
                <h1 className="main--header">Coming Soon.</h1>
                <h1 className="main--header">Please check back later.</h1>            


            </div>
            
            
        </section>
        

    )
    
}