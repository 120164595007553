
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

// import "./style.css"



export default function NotFoundPage() {

  
  
  return (
  
      <div className="notfound--page">
          
          <h1 className="error">404</h1>
          <h1>The page you are trying to access does not exist.</h1>
          <h1>Go back to Homepage?</h1>


      </div>
      
    
  )
  
}

