
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

// import "./style.css"


// import NewsLetter from "../components/NewsLetter"
import ComingSoon from "../components/ComingSoon"
import ModelIntro from '../components/ModelIntro';
import Notes from "../components/Notes"

export default function Home() {

  
  return (
  
      <div className="homepage--container">
          
          {<ModelIntro />}
          {<ComingSoon />}
          {<Notes />}

      </div>
      
    
  )
  
}

