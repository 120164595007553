
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

import './style.css';

import App from './App';





ReactDOM.createRoot(document.getElementById('root')).render(

   <App />

)


