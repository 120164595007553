import React from "react"

import nav_logo from "../images/nav-logo.svg"

import { NavLink } from "react-router-dom"

export default function Footer() {
    
    return (

        <footer>        
    
            <div>
                <small>Copyright &copy; 2024 MyChinesePal.com</small>

            </div>
    
    
            <div className="footer--links">
               

                    <li>
                            <NavLink 
                                to="/tac"
                                className={({isActive}) => isActive ? "link--current" : "link--noncurrent"}
                                >
                                Terms & Conditions
                            </NavLink>
                    </li>

                    <li>
                            <NavLink 
                                to="/ua"
                                className={({isActive}) => isActive ? "link--current" : "link--noncurrent"}
                                >
                                User Agreement
                            </NavLink>
                    </li>

                    <li>
                            <NavLink 
                                to="/dp"
                                className={({isActive}) => isActive ? "link--current" : "link--noncurrent"}
                                >
                                Data Policy
                            </NavLink>
                    </li>

                    <li>
                            <NavLink 
                                to="/uoai"
                                className={({isActive}) => isActive ? "link--current" : "link--noncurrent"}
                                >
                                Use Of AI Statement
                            </NavLink>
                    </li>
               
                    
               
              
            </div>
            
            <div>
                <img 
                    src={nav_logo}
                    alt="service logo"
                    className="footer--icon"
                />
                
            </div>
            

        </footer>
        
    )
    
}