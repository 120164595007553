import { React, useState } from "react";
import {NavLink} from "react-router-dom";


import nav_logo from "../images/nav-logo.svg";



export default function Navbar() {
    

    return (
        
          <div>
            
            <nav className = "nav">  
        
            <NavLink to="/">
                <img 
                    src={nav_logo}
                    alt="service logo"
                    className="nav--icon"
                />
            </NavLink>
            
            
            <ul className="nav--menu">
                
                <li>
                    <NavLink 
                        to="/about"
                        className={({isActive}) => isActive ? "link--current" : null}
                        >
                        About
                    </NavLink>
                </li>
                
                
            </ul>

            </nav>

          </div>
        
    )
    
}