import React from "react"



export default function Intro() {
    
    return (
 
        
 
        <section className="homepage--banner">
        
            <div>
                <h1 className="main--header">Master Mandarin conversations with Your AI Partner.</h1>
                
                <h2 className="main--subheader">Get familiar with the language flow.</h2>
                <h2 className="main--subheader">Level up your conversation skills.</h2>
                <h2 className="main--subheader">Break that plateu.</h2>

            
                <h2 className="main--subheader">No more awkward conversations and disappearing partners.</h2>


                <h3 className="main--text">Right in your browser. 24/7. * **</h3>


            </div>
            
            
        </section>
        

    )
    
}